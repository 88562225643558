import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavMenuPrimitives } from '@abyss/web/ui/NavMenu';
import { Text } from '@abyss/web/ui/Text';
import { PageHeaderPrimitives } from '@abyss/web/ui/PageHeader';
import { Layout } from '@abyss/web/ui/Layout';
import { Brandmark } from '@abyss/web/ui/Brandmark';
import { Box } from '@abyss/web/ui/Box';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { getCachedUserDetails, getHelpHash } from '@src/utils';
import { getEEMSURL, getNewEEMSURL, getEnvironment } from '@src/env.config';
import { Alert } from '@abyss/web/ui/Alert';
import { setItems } from '../../store/FilesSlice';
import { useLazyGetAllParentFilesQuery } from '../../store/services/ParentServices';

const defaultValues = {
  searchFor: '',
  searchType: 'subId',
  fileProcessingStatus: '',
};

export const Header = ({ isAuth = false }: { isAuth: null | boolean }) => {
  const [isInternal, setIsInternal] = useState(false);
  const [homeLink, setHomeLink] = useState('');
  const matches = useMediaQuery('(min-width: 500px)');
  const dispatch = useDispatch();
  const router = useRouter();

  const [triggerData, { data: parentFilesList }] =
    useLazyGetAllParentFilesQuery();

  useEffect(() => {
    const { userType } = getCachedUserDetails();
    setIsInternal(userType === 'I');
  }, []);

  useEffect(() => {
    if (parentFilesList) {
      dispatch(setItems(parentFilesList));
    }
  }, [parentFilesList]);
  const onHomeClick = (e) => {
    const currentURI = window.location.href;
    if (currentURI.includes('/home/')) {
      setHomeLink('/');
    } else {
      triggerData(defaultValues);
    }
  };

  const handleHelp = () => {
    window.open(getEEMSURL() + getHelpHash());
  };
  const newEEMSURL = getNewEEMSURL();

  const isProd = newEEMSURL.includes('prod');

  console.log('isProd', isProd);
  const handleLogout = async () => {
    console.log(await router.navigate('/logout'));
    await router.navigate('/logout');
  };

  return (
    <PageHeaderPrimitives.Provider>
      <PageHeaderPrimitives.Container>
        <Layout.Group>
          <PageHeaderPrimitives.HamburgerMenu
            onClick={() => {}}
            type="button"
            aria-label="Menu"
            aria-haspopup="dialog"
          >
            <IconMaterial icon="menu" color="$primary1" />{' '}
          </PageHeaderPrimitives.HamburgerMenu>
          <PageHeaderPrimitives.Brandmark
            logo={
              <Brandmark
                size="20px"
                affiliate="uhc"
                variant="u_mark"
                color="blue"
                brand="uhc"
              />
            }
            logoTitle={isInternal ? 'EEMS PRIME' : 'SMALL BUSINESS'}
            css={{
              'abyss-page-header-logo': {
                flexShrink: 'inherit',
                width: 'max-content',
              },
            }}
          />
        </Layout.Group>
        {/* <Layout.Group space={20}>
          <Alert
            title="New! The EEMS Dashboard has undergone an update - all navigation and user tools remain unchanged"
            variant="info"
            isVisible={true}
          />
        </Layout.Group> */}
        {matches && isAuth && (
          <Layout.Group space={20}>
            <NavMenuPrimitives.Root variant="inverted" zIndex={202} hasOverlay>
              <NavMenuPrimitives.List>
                <NavMenuPrimitives.Item>
                  <NavMenuPrimitives.Link href="#" variant="inverted">
                    <Layout.Group space={0}>
                      User
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '14px',
                          width: '14px',
                          marginLeft: '4px',
                          fontSize: '10px',
                          fontWeight: '$bold',
                        }}
                      >
                        <IconMaterial
                          icon="person"
                          size="$sm"
                          color="$primary1"
                        />
                      </div>
                    </Layout.Group>
                  </NavMenuPrimitives.Link>
                  <Text
                    css={{
                      fontWeight: 'light',
                      textTransform: 'capitalize',
                      color: 'var(--abyss-colors-primary1)',
                    }}
                  >
                    <strong>{getEnvironment()}</strong>
                  </Text>
                </NavMenuPrimitives.Item>
              </NavMenuPrimitives.List>
              <NavMenuPrimitives.Viewport />
            </NavMenuPrimitives.Root>
          </Layout.Group>
        )}
      </PageHeaderPrimitives.Container>

      <Box
        css={{
          padding: 0,
          margin: 0,
          backgroundColor: 'transparent',
          '.abyss-nav-menu-root': {
            backgroundColor: '$primary1 !important',
            '.abyss-nav-menu-container': {
              backgroundColor: '$primary1 !important',
              '.abyss-nav-menu-link': {
                color: '$white !important',
                textDecoration: 'none',
              },
              '.abyss-nav-menu-link:hover': {
                color: '$primary1 !important',
              },
            },
          },
        }}
      >
        <NavMenuPrimitives.Root hasOverlay>
          <Layout.Group
            css={{ justifyContent: 'space-between', width: '100%' }}
          >
            <NavMenuPrimitives.List>
              {isAuth && (
                <React.Fragment>
                  <NavMenuPrimitives.Item>
                    <NavMenuPrimitives.Link
                      href={homeLink}
                      onClick={onHomeClick}
                    >
                      Home
                    </NavMenuPrimitives.Link>
                  </NavMenuPrimitives.Item>

                  {isInternal ? (
                    <React.Fragment>
                      {' '}
                      <NavMenuPrimitives.Item>
                        <NavMenuPrimitives.Link
                          href={`${getNewEEMSURL()}profile/search`}
                        >
                          View <br /> Profile
                        </NavMenuPrimitives.Link>
                      </NavMenuPrimitives.Item>
                      {!isProd ? (
                        <React.Fragment>
                          <NavMenuPrimitives.Item>
                            <NavMenuPrimitives.Link
                              href={`${getNewEEMSURL()}profile/add`}
                            >
                              Add <br /> Profile
                            </NavMenuPrimitives.Link>
                          </NavMenuPrimitives.Item>
                        </React.Fragment>
                      ) : null}
                      <NavMenuPrimitives.Item
                        position="end"
                        css={{ marginLeft: '750px' }}
                      >
                        <NavMenuPrimitives.Link onClick={handleHelp}>
                          Help
                        </NavMenuPrimitives.Link>
                      </NavMenuPrimitives.Item>
                      <NavMenuPrimitives.Item position="end">
                        <NavMenuPrimitives.Link onClick={handleLogout}>
                          Logout
                        </NavMenuPrimitives.Link>
                      </NavMenuPrimitives.Item>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              )}
            </NavMenuPrimitives.List>
          </Layout.Group>
        </NavMenuPrimitives.Root>
      </Box>
    </PageHeaderPrimitives.Provider>
  );
};
